import { decode } from 'js-base64';
import { pluck } from 'ramda';

const CONFERENCE_TYPE_IMAGES = {
  FAM: {
    imageCover: 'https://rlcf.church/graphics/conferences/fam-cover.jpg',
    imageUnfurl: 'https://rlcf.church/graphics/conferences/fam-unfurl.jpg',
    imageThumb: 'https://rlcf.church/graphics/conferences/fam-thumb.jpg',
  },
  MEN: {
    imageCover: 'https://rlcf.church/graphics/conferences/men-cover.jpg',
    imageUnfurl: 'https://rlcf.church/graphics/conferences/men-unfurl.jpg',
    imageThumb: 'https://rlcf.church/graphics/conferences/men-thumb.jpg',
  },
  SNG: {
    imageCover: 'https://rlcf.church/graphics/conferences/sng-cover.jpg',
    imageUnfurl: 'https://rlcf.church/graphics/conferences/sng-unfurl.jpg',
    imageThumb: 'https://rlcf.church/graphics/conferences/sng-thumb.jpg',
  },
  ONL: {
    imageCover: 'https://rlcf.church/graphics/conferences/onl-cover.jpg',
    imageUnfurl: 'https://rlcf.church/graphics/conferences/onl-unfurl.jpg',
    imageThumb: 'https://rlcf.church/graphics/conferences/onl-thumb.jpg',
  },
};

export const getUpcomingConferences = (conferences) => {
  const upcomingConferences = [];
  for (let i = 0; i < conferences.length; i += 1) {
    const conference = conferences[i];
    // show upcoming for an extra day as some padding for resolved browser timestamps
    if (
      new Date(conference.endDate).getTime() + 24 * 60 * 60 * 1000 <
      new Date().getTime()
    ) {
      break;
    }
    upcomingConferences.unshift(conference);
  }
  return upcomingConferences;
};

export const getGCalId = ({ id }) => decode(id).toString().split(':')[1];

export const items = ({ edges }) => pluck('node', edges);

export const getConferenceImage = (conference, imageAttr = 'image') => {
  return CONFERENCE_TYPE_IMAGES[conference.conferenceType][imageAttr];
};

export const formatMemoryVerseLevels = (memoryVerse) =>
  ['Adult', 'Junior', 'Primary', 'Preschool']
    .filter((level) => memoryVerse[`level${level}`])
    .join(' / ');

export default {
  getUpcomingConferences,
  getGCalId,
  items,
  getConferenceImage,
  formatMemoryVerseLevels,
};
